<template>
  <div class="inventory-shipping-station-wrapper pt-3">
    <div class="columns">
      <div class="column is-3 right-border bg-is-flex-column">
        <h2 class="title bottom-border is-5 mb-2 pb-1 has-text-centered">
          Raw Material
        </h2>
        <div class="max-height-300 bottom-border">
          <div
            :key="temp.template_id"
            v-for="temp in Object.values(
              currentGame.params.productsTemplates
            ).sort((a, b) => {
              return a.template_id.localeCompare(b.template_id);
            })"
            class="has-text-centered"
          >
            <div
              class="select-product-border is-relative"
              @click="chooseTemplate(temp)"
              :class="{
                'is-selected':
                  selectedTemplate &&
                  selectedTemplate.template_id === temp.template_id,
                'is-disabled': isDisab || !availTemplates[temp.template_id]
              }"
            >
              <b-tag type="is-info is-light" class="tag-top-right" rounded
                ><b-icon icon="infinity" size="is-small" class="mx-1"></b-icon
              ></b-tag>
              <b class="is-size-6">{{ temp.name }}</b>
              <div class="columns is-vcentered">
                <div class="column mt-1">
                  <div class="max-width-150">
                    <component
                      v-bind:is="leanProductFigures[temp.type]"
                      :product="temp"
                      :station="null"
                      :show-guides="false"
                      :show-finished="false"
                      :is-disabled="
                        isDisab || !availTemplates[temp.template_id]
                      "
                      :is-template="true"
                    ></component>
                  </div>
                </div>
                <div class="column is-size-7 pt-0">
                  <p>
                    Tot Orders:
                    <b class="has-text-info">{{
                      totalTemplateNeed[temp.template_id] || 0
                    }}</b>
                  </p>
                  <p>
                    Vs. In Prod:
                    <b class="has-text-success">{{
                      totalTemplateStock[temp.template_id]
                    }}</b>
                  </p>
                  <p
                    class="has-text-weight-bold"
                    v-if="
                      totalTemplateNeed[temp.template_id] -
                        totalTemplateStock[temp.template_id] >
                        0
                    "
                  >
                    <b-icon icon="arrow-right" size="is-small"></b-icon> Produce
                    <b class="has-text-danger">
                      {{
                        totalTemplateNeed[temp.template_id] -
                          totalTemplateStock[temp.template_id]
                      }}
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-auto pt-1">
          <b
            v-if="!selectedTemplate && !isDisab"
            class="mt-5 label is-size-5 has-text-danger"
            >Select a Product Template</b
          >
          <div class="py-6" v-if="isChangeover">
            <p class="has-text-centered is-size-5 has-text-dark">
              Sending {{ selectedRawMaterialQuantity }} units to
              {{ selectedRawMaterialStation.name }}
            </p>
            <b-progress
              type="is-info"
              size="is-large"
              :value="changeoverAnim.pourc"
              :min="0"
              :max="100"
            ></b-progress>
          </div>
          <div v-if="selectedTemplate && !isChangeover">
            <p>
              Template :
              <b>{{ selectedTemplate.name }}</b>
            </p>
            <p v-if="selectedRawMaterialStation">
              First station :
              <b>
                {{ selectedRawMaterialStation.name }}
                <!-- <a
                  class="has-text-info pl-2"
                  v-if="!rawMaterialUpdateStation"
                  @click="rawMaterialUpdateStation = true"
                >
                  <b-icon size="is-small" icon="pencil"></b-icon>
                </a> -->
              </b>
            </p>
            <b-field
              class="mt-2"
              expanded
              v-if="rawMaterialUpdateStation || !selectedRawMaterialStation"
              grouped
            >
              <b-select
                placeholder="Select a Station"
                v-model="selectedRawMaterialStation"
                expanded
              >
                <option
                  v-for="option in allStations"
                  :value="option"
                  :key="option.id"
                >
                  {{ option.name }}
                  #{{ option.id }}
                </option>
              </b-select>
              <p class="control">
                <b-button
                  type="is-info"
                  @click="rawMaterialUpdateStation = false"
                  >OK</b-button
                >
              </p>
            </b-field>
            <b-field class="mt-2" expanded>
              <b-input
                placeholder="Quantity"
                size="is-medium"
                v-model.number="selectedRawMaterialQuantity"
                @keyup.enter.native="launchInProd"
                type="number"
                :min="0"
                :max="100"
                :disabled="isDisab"
              ></b-input>
              <div
                class="control is-size-6 pl-2 has-text-weight-bold"
                v-if="selectedTemplate.batchSize"
                :class="{
                  'has-text-success': batchOrder.isMultiple,
                  'has-text-danger': !batchOrder.isMultiple
                }"
              >
                <p>Batch size = {{ selectedTemplate.batchSize }} units.</p>
                <p class="has-text-weight-bold">
                  <a
                    v-if="!batchOrder.isMultiple"
                    @click="selectedRawMaterialQuantity = batchOrder.roundOrder"
                  >
                    <b-icon icon="arrow-right" size="is-small"></b-icon>
                    Round to {{ batchOrder.roundOrder }} ({{
                      batchOrder.nbBatches
                    }}
                    batch{{ batchOrder.nbBatches > 1 ? "es" : "" }})</a
                  >
                </p>
              </div>
            </b-field>

            <div class="buttons is-centered" v-if="checkEnoughSpaceNext">
              <b-button
                size="is-medium"
                :disabled="
                  !selectedRawMaterialQuantity ||
                    selectedRawMaterialQuantity > 100 ||
                    !selectedTemplate ||
                    !batchOrder.isMultiple ||
                    isDisab
                "
                rounded
                type="is-info"
                @click="launchInProd"
              >
                Begin Prod of {{ selectedRawMaterialQuantity }} unit{{
                  selectedRawMaterialQuantity > 1 ? "s" : ""
                }}
              </b-button>
            </div>
            <div
              class="has-text-centered label mt-2 has-text-danger"
              v-if="selectedRawMaterialStation && !checkEnoughSpaceNext"
            >
              Kanban: not enough space in
              <em>{{ selectedRawMaterialStation.name }}</em>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3 right-border bg-is-flex-column">
        <div>
          <h2 class="title bottom-border is-5 mb-2 pb-1 has-text-centered">
            WIP-in (Finished Goods)
            {{
              faci.params.kanbanCapa
                ? `(${wipProducts.all.length}/${faci.params.kanbanCapa})`
                : ""
            }}
          </h2>
          <div class="max-height-400">
            <stock-list
              :faci="faci"
              zone="wipin"
              v-model="wipProducts"
              :is-disab="isDisab"
              ref="wipstocklist"
            ></stock-list>
          </div>
        </div>
        <!-- <div class="mt-auto top-border">
          <move-stock
            v-if="wipProducts.all.length"
            :faci="faci"
            :is-all="!wipProducts.selected.length"
            :is-disab="isDisab"
            :all-products="
              wipProducts.selected.length
                ? wipProducts.selected
                : wipProducts.all
            "
            @movedstock="$refs['wipstocklist'].resetSelection()"
          ></move-stock>
        </div> -->
      </div>
      <div class="column">
        <div class="mb-1">
          <h2 class="title bottom-border is-5 has-text-centered">
            Customer Orders
          </h2>
        </div>
        <lean-orders-table
          :is-active="!isDisab"
          :wip-products="wipProducts"
          :products="currentProducts"
          :game="currentGame"
          :orders="currentLeanGameOrders"
          @sentorder="$refs['wipstocklist'].resetSelection()"
        ></lean-orders-table>
      </div>
    </div>
  </div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import { mapGetters } from "vuex";
import StockList from "@/components/lean/Play/StationElements/StockList.vue";
import MoveStock from "@/components/lean/Play/StationElements/MoveStock.vue";
import LeanOrdersTable from "@/components/lean/Stats/LeanOrdersTable.vue";
import firebase from "@/firebaseConfig";

export default {
  name: "InventoryShippingStation",
  components: {
    StockList,
    LeanOrdersTable,
    MoveStock
  },
  computed: {
    ...mapGetters([
      "currentGame",
      "isLeanGameActive",
      "isCreator",
      "myFaci",
      "leanProductFigures",
      "movementDurations",
      "currentProducts",
      "currentLeanGameOrders"
    ]),
    allStations() {
      return Object.values(this.currentGame.facilities).filter(
        fac => true || fac.id !== this.faci.id
      );
    },
    isDisab() {
      return (
        !this.isLeanGameActive ||
        ((!this.myFaci || this.myFaci.id !== this.faci.id) && !this.isCreator)
      );
    },
    availTemplates() {
      let obj = {};
      if (this.currentLeanGameOrders) {
        this.currentLeanGameOrders.forEach(order => {
          obj[order.template_id] = Object.values(
            this.currentGame.params.productsTemplates
          ).find(tt => tt.template_id === order.template_id);
        });
      }
      return obj;
    },
    totalTemplateStock() {
      let obj = {};
      Object.values(this.currentGame.params.productsTemplates).forEach(temp => {
        obj[temp.template_id] = 0;
        this.currentProducts.forEach(prod => {
          if (!prod.fulfilled_at && prod.template_id === temp.template_id) {
            obj[temp.template_id] += 1;
          }
        });
      });
      return obj;
    },
    totalTemplateNeed() {
      let obj = {};
      this.currentLeanGameOrders.forEach(order => {
        if (!order.fulfilled_at) {
          if (!obj[order.template_id]) {
            obj[order.template_id] = 0;
          }
          obj[order.template_id] += order.qty;
        }
      });
      return obj;
    },
    batchOrder() {
      if (
        !this.selectedTemplate ||
        !this.selectedTemplate.batchSize ||
        this.selectedTemplate.batchSize <= 1
      ) {
        return {
          isMultiple: true
        };
      }
      let nbBatches =
        this.selectedRawMaterialQuantity <= 0
          ? 0
          : Math.floor(
              this.selectedRawMaterialQuantity / this.selectedTemplate.batchSize
            ) + 1;
      return {
        isMultiple:
          this.selectedRawMaterialQuantity / this.selectedTemplate.batchSize -
            Math.floor(
              this.selectedRawMaterialQuantity / this.selectedTemplate.batchSize
            ) ===
          0
            ? true
            : false,
        roundOrder: this.selectedTemplate.batchSize * nbBatches,
        nbBatches: nbBatches
      };
    },
    checkEnoughSpaceNext() {
      let check = true;
      if (
        this.selectedRawMaterialStation &&
        this.selectedRawMaterialStation.params.kanbanCapa
      ) {
        let countProd = this.currentProducts.filter(prod => {
          return (
            prod.pos.fid === this.selectedRawMaterialStation.id &&
            prod.pos.zone === "wipin" &&
            !prod.fulfilled_at
          );
        }).length;
        if (
          countProd + this.selectedRawMaterialQuantity >
          this.selectedRawMaterialStation.params.kanbanCapa
        ) {
          check = false;
        }
      }
      return check;
    }
  },
  methods: {
    chooseTemplate(temp) {
      if (this.isDisab || !this.availTemplates[temp.template_id]) {
        return;
      }
      this.selectedTemplate = temp;
      if (temp.batchSize) {
        this.selectedRawMaterialQuantity = temp.batchSize;
      }
    },
    launchInProd() {
      if (
        !this.selectedRawMaterialQuantity ||
        this.selectedRawMaterialQuantity > 100 ||
        !this.batchOrder.isMultiple ||
        !this.selectedRawMaterialStation ||
        !this.selectedTemplate ||
        this.isDisab
      ) {
        return;
      }
      this.isChangeover = true;
      this.changeoverAnim.pourc = 0;
      anime({
        targets: this.changeoverAnim,
        pourc: 100,
        duration: this.movementDurations
          ? this.movementDurations[this.faci.id].to[
              this.selectedRawMaterialStation.id
            ]
          : 200,
        easing: "linear"
      }).finished.then(() => {
        this.isChangeover = false;
        this.changeoverAnim.pourc = 0;
        const timestamp = firebase.firestore.Timestamp.now();
        let obj = {
          gid: this.currentGame.id,
          pos: { fid: this.selectedRawMaterialStation.id, zone: "wipin" },
          fulfilled_at: null,
          created_at: timestamp,
          stats: {
            moves: [
              {
                fid: this.faci.id,
                zone: "wipout",
                enter_at: timestamp,
                leave_at: timestamp,
                move_duration: this.movementDurations
                  ? this.movementDurations[this.faci.id].to[
                      this.selectedRawMaterialStation.id
                    ]
                  : 200,
                actions: []
              },
              {
                fid: this.selectedRawMaterialStation.id,
                zone: "wipin",
                enter_at: timestamp,
                move_duration: 0,
                actions: []
              }
            ]
          },
          ...this.selectedTemplate
        };
        for (let ii = 0; ii < this.selectedRawMaterialQuantity; ii++) {
          this.$store.dispatch("createLeanProduct", obj);
        }
        this.$buefy.toast.open(
          `Started the production of ${this.selectedRawMaterialQuantity} unit${
            this.selectedRawMaterialQuantity > 1 ? "s" : ""
          }`
        );
      });
    }
  },
  props: { faci: Object },
  created() {
    if (this.faci.prioNextStation) {
      this.selectedRawMaterialStation = this.currentGame.facilities[
        this.faci.prioNextStation
      ];
    }
  },
  watch: {
    availTemplates: {
      handler() {
        if (
          !this.selectedTemplate &&
          this.availTemplates &&
          Object.values(this.availTemplates).length > 0
        ) {
          this.selectedTemplate = Object.values(this.availTemplates)[0];
          if (
            this.selectedTemplate.batchSize &&
            this.selectedRawMaterialQuantity < this.selectedTemplate.batchSize
          ) {
            this.selectedRawMaterialQuantity = this.selectedTemplate.batchSize;
          }
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      wipProducts: { all: [], selected: [] },
      rawMaterialUpdateStation: false,
      selectedRawMaterialQuantity: 1,
      selectedRawMaterialStation: null,
      selectedTemplate: null,
      isChangeover: false,
      changeoverAnim: { pourc: 0 }
    };
  }
};
</script>

<style lang="scss">
.inventory-shipping-station-wrapper {
}
</style>
